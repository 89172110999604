import { useIsAuthenticated } from "@azure/msal-react";
import permissions from "lib/constants/permissions/permissions";
import ResourceTypes from "lib/constants/permissions/resourceTypes";
import paths from "lib/constants/routing/paths";
import isAuthorizedForResource, { getUserPermissionsFromUserData } from "lib/helpers/permissions/permissionsHelper";
import resolveRouteToTemplate from "lib/helpers/routes/resolveRouteToTemplate";
import useOrganisationNavigate from "lib/hooks/navigate/useOrganisationNavigate";
import { ReactComponent as PinSidebarIcon } from "lib/icons/menu.svg";
import { ReactComponent as CloseIcon } from "lib/icons/x.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectUserData } from "redux/user/userSlice";
import AdminSidebarOptions from "./contextualSidebars/AdminSidebar";
import PartnerSidebarOptions from "./contextualSidebars/PartnerSidebar";
import "./Sidebar.css";
import SidebarOrganisationInfo from "./SidebarOrganisationInfo";

const Sidebar = ({ isSidebarExpanded }) => {
  const location = useLocation();
  const { navigate, generateFullPath } = useOrganisationNavigate();
  const [expanded, setExpanded] = useState(false);
  const [hovering, setHovering] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { data: userData } = useSelector((state) => selectUserData(state));
  const resolvedTemplate = resolveRouteToTemplate(location.pathname);

  useEffect(() => {
    try {
      if (location && location.pathname === "/" && userData) {
        navigate(paths.Default.link);
      }
    } catch {}
  }, [isAuthenticated, userData]);

  const getUrlOrganisationId = () => resolvedTemplate?.match?.params?.organisationId;

  const getMenuItemsForCurrentContext = () => {
    if (!isAuthenticated || !userData) return [];
    //TODO if (resolvedTemplate?.params?.applicationId) return ApplicationSidebarOptions;
    var organisationId = getUrlOrganisationId();
    if (organisationId) {
      return organisationId === process.env.REACT_APP_ADMIN_ORGANISATION_ID ? AdminSidebarOptions : PartnerSidebarOptions;
    }
    return isUserAdminOrInAnAdminOrg() ? AdminSidebarOptions : PartnerSidebarOptions;
  };

  const getMenuItemsForCurrentUser = () => {
    return getMenuItemsForCurrentContext().filter((option) =>
      isAuthorizedForResource(ResourceTypes.Paths, option.path, userData)
    );
  };

  const getNavItemsForRender = () => {
    return getMenuItemsForCurrentUser().map((option, i) => (
      <Link
        key={i}
        to={generateFullPath(option.path)}
        className={`sidebarNavItem ${resolvedTemplate?.match?.pattern?.path === option.path ? "active" : null}`}>
        <span className="sidebarNavItemIcon">{option.icon}</span>
        <span className="sidebarNavItemText">{option.text}</span>
      </Link>
    ));
  };

  const getLogo = () => {
    if (expanded || hovering) return <img src="/assets/images/logos/logo.png" className="img-fluid" />;
    return <img src="/assets/images/logos/logo-collapse.png" />;
  };

  useEffect(() => {
    expanded || hovering ? isSidebarExpanded(true) : isSidebarExpanded(false);
  }, [expanded, hovering]);

  const getToggler = () => {
    if (!isAuthenticated) return <></>;
    if (expanded) return <CloseIcon onClick={() => setExpanded(false)} src="/assets/icons/x.svg" className="sidenavToggler" />;
    return <PinSidebarIcon onClick={() => setExpanded(true)} src="/assets/icons/menu.svg" className="sidenavToggler" />;
  };

  const isUserAdminOrInAnAdminOrg = () => {
    let userPermissions = getUserPermissionsFromUserData(userData);
    return (
      userPermissions.includes(permissions.GlobalAdmin) ||
      userData?.organisationId === process.env.REACT_APP_ADMIN_ORGANISATION_ID
    );
  };

  const hasSwitchedOrganisation = () => {
    return isAuthenticated && isUserAdminOrInAnAdminOrg() && getUrlOrganisationId() !== userData.organisationId;
  };

  const getOrganisationToggler = () => {
    if (hasSwitchedOrganisation())
      return (
        <div className="sidebarNavItemHiddenOnCollapse">
          <hr></hr>
          <Link to={generateFullPath(paths.Default.link, userData.organisationId)}>Return to Administration</Link>
        </div>
      );
    return <></>;
  };
  return (
    <div
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      className={expanded || hovering ? "expandedSidebar" : "collapsedSidebar"}
      id="sidebar"
      onSelect={(selected) => {
        const to = selected;
        if (location.pathname !== to) {
          navigate(to);
        }
      }}>
      <div id="brand" className="bg-white">
        <a href="/">{getLogo()}</a>
        <div>{getToggler()}</div>
      </div>
      {getUrlOrganisationId() && <SidebarOrganisationInfo organisationId={getUrlOrganisationId()} />}
      <div id="sideNavContainer">{getNavItemsForRender()}</div>
      <div className="text-center">{getOrganisationToggler()}</div>
    </div>
  );
};
export default Sidebar;
