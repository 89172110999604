import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Grid } from "react-loader-spinner";
import "./Loader.css";
const Loader = ({ visible, centerAbsoloute = false, centerInline = false, animated = false, size = "md", color = "black" }) => {
  const getClassName = () => {
    let classNames = ["flex"];
    if (centerAbsoloute) classNames.push("centerAbsoloute");
    if (centerInline) classNames.push("centerInline");
    if (animated) classNames.push("animated");
    return classNames.join(" ");
  };

  const getSize = () => {
    switch (size) {
      case "sm":
        return {
          height: "1.5rem",
        };
      case "md":
        return {
          height: "2.5rem",
        };
      case "lg":
        return {
          height: "3.5rem",
        };
    }
  };

  return (
    <div className={getClassName()}>
      <Grid color={color} {...getSize()} visible={visible} />
    </div>
  );
};
export default Loader;
