const ValidatedInput = (props) => {
  const { error, ...rest } = props;
  return (
    <div>
      <input {...rest} />
      {error && <span className="errorMsg">{error}</span>}
    </div>
  );
};
export default ValidatedInput;
