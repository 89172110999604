import { displayToast } from "components/utility/alerts/Toast";
import { queryLogs } from "lib/fetch/requests/logsRequests";
import useFetch from "lib/fetch/useFetch";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import LogsDisplay from "./LogsDisplay";

const LogsModalDisplay = ({ correlationId, handleClose }) => {
  const { organisationId } = useParams();
  const [modalLogs, fetchModalLogs, fetchingModalLogs, errorFetchingModalLogs] = useFetch(queryLogs(organisationId));
  const [cachedLogs, setCachedLogs] = useState({});
  const [show, setShow] = useState(false);

  const handleModalClose = () => {
    handleClose();
    setShow(false);
  };

  useEffect(() => {
    if (correlationId) {
      if (!cachedLogs.hasOwnProperty(correlationId)) fetchModalLogs({ conditions: { correlationId } });
      setShow(true);
    }
  }, [correlationId]);

  useEffect(() => {
    if (!fetchingModalLogs && !errorFetchingModalLogs) setCachedLogs({ ...cachedLogs, [correlationId]: modalLogs });
  }, [modalLogs]);

  useEffect(() => {
    if (errorFetchingModalLogs && !fetchingModalLogs) displayToast("Error occurred fetching logs.", "error");
  }, [errorFetchingModalLogs, fetchingModalLogs]);

  return (
    <>
      <Modal id="dialogModal" show={show} size={"lg"} onHide={handleModalClose}>
        <LogsDisplay loading={fetchingModalLogs} data={cachedLogs[correlationId]} totalLogs={modalLogs?.length} />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default LogsModalDisplay;
