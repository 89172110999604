import { Bar, BarChart as Chart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "./Statistic.css";

const BarChart = ({ height, heading, data, valueConfig, dataKey }) => {
  return (
    <div className="ampCommonContainer" style={{ height: height }}>
      <p className="statHeading">{heading}</p>
      <ResponsiveContainer debounce={300} width={"95%"} height={"90%"}>
        <Chart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataKey} />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          {valueConfig?.map((config) => {
            return <Bar name={config.name} type="monotone" dataKey={config.dataKey} fill={config.fill} />;
          })}
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;
