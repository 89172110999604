import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import NavigationPath from "components/utility/navigation/NavigationPath";
import resourceTypes from "lib/constants/permissions/resourceTypes";
import paths from "lib/constants/routing/paths";
import { getOrganisations } from "lib/fetch/requests/organisationsRequest";
import useFetch from "lib/fetch/useFetch";
import isAuthorizedForResource from "lib/helpers/permissions/permissionsHelper";
import useOrganisationNavigate from "lib/hooks/navigate/useOrganisationNavigate";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectUserData } from "redux/user/userSlice";
import OrganisationsList from "./components/OrganisationsList";

const OrganisationManagement = () => {
  const [data, loadData, isFetching, error] = useFetch(getOrganisations());
  const { navigate } = useOrganisationNavigate();
  const { data: userData } = useSelector((state) => selectUserData(state));

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (error) displayToast("An error occurred fetching organisations.", "error");
  }, [error]);

  const displayOrganisations = () => {
    return !isFetching && <OrganisationsList data={data} />;
  };

  const displayCreateOrganisationButton = () => {
    return (
      !error &&
      !isFetching &&
      isAuthorizedForResource(resourceTypes.Paths, paths.CreateOrganisation.link, userData) && (
        <div className="ampFlexCenter">
          <Button variant="outline-danger" size="md" onClick={() => navigate(paths.CreateOrganisation.link)}>
            Add Organisation
          </Button>
        </div>
      )
    );
  };

  const displayErrorMessage = () => {
    return (
      !isFetching && (
        <>
          <div className="ampFlexCenter">
            <Button variant="outline-primary" size="lg" onClick={() => loadData()}>
              Refresh
            </Button>
          </div>
        </>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{"Organisations | AMP"}</title>
      </Helmet>
      <div className="ampScreenContainer">
        <NavigationPath />
        <h1 className="ampPageHeading">Organisations</h1>
        <hr />
        {isFetching && <Loader centerInline />}
        {error ? displayErrorMessage() : displayOrganisations()}
        {displayCreateOrganisationButton()}
      </div>
    </>
  );
};

export default OrganisationManagement;
