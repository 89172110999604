import resourcePermissions from "lib/constants/permissions/resourcePermissions";
import paths from "lib/constants/routing/paths";
import { ReactComponent as LogsIcon } from "lib/icons/activity.svg";
import { ReactComponent as UserIcon } from "lib/icons/user.svg";

const PartnerSidebarOptions = [
  {
    icon: <UserIcon />,
    text: "Users",
    permissionRequired: resourcePermissions[paths.ViewUsers.link],
    path: paths.ViewUsers.link,
  },
  {
    icon: <LogsIcon />,
    text: "Logs",
    permissionRequired: resourcePermissions[paths.ViewLogs.link],
    path: paths.ViewLogs.link,
  },
];
export default PartnerSidebarOptions;
