import { Cell, Legend, Pie, PieChart as Chart, ResponsiveContainer, Tooltip } from "recharts";
import "./Statistic.css";

const PieChart = ({ height, heading, data, valueConfig }) => {
  return (
    <div className="ampCommonContainer" style={{ height }}>
      <p className="statHeading">{heading}</p>
      <ResponsiveContainer debounce={300} width={"100%"} height={"100%"}>
        <Chart>
          <Tooltip />
          <Legend />
          <Pie
            labelLine={false}
            label={renderCustomizedLabel}
            data={data}
            cy={height / 4 + 20}
            innerRadius={0}
            outerRadius={height / 4}
            paddingAngle={1}>
            {valueConfig?.map((config) => {
              return <Cell fill={config.fill} />;
            })}
          </Pie>
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChart;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name, value }) => {
  const RADIAN = Math.PI / 180;
  const radius = 20 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {name}: {value} ({(percent * 100).toFixed(0)}%)
    </text>
  );
};
