import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import userReducer from "./user/userSlice";
import thunk from "redux-thunk";
import organisationReducer from "./organisation/organisationSlice";

const reducers = combineReducers({
  user: userReducer,
  organisation: organisationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/loggedOut") {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem(`persist:${process.env.REACT_APP_REDUX_STORE_KEY}`);
    state = {};
  }
  return reducers(state, action);
};

const persistConfig = {
  key: process.env.REACT_APP_REDUX_STORE_KEY,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
