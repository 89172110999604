import { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import NavigationPath from "components/utility/navigation/NavigationPath";
import paths from "lib/constants/routing/paths";
import { createUser, deleteUserById, getRoles, getUserById, updateUserById } from "lib/fetch/requests/userRequests";
import useFetch from "lib/fetch/useFetch";
import useRequest from "lib/fetch/useRequest";
import useOrganisationNavigate from "lib/hooks/navigate/useOrganisationNavigate";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectUserData } from "redux/user/userSlice";
import EditUserForm from "./components/EditUserForm";
import "./UserManagement.css";

const EditUser = () => {
  const user = useSelector((state) => selectUserData(state).data);
  const { userId, organisationId } = useParams();
  const [userData, getUser, isFetchingUser, errorFetchingUser] = useFetch(getUserById(organisationId, userId));
  const [saveUser, isSavingUser] = useRequest(updateUserById(organisationId, userId));
  const [createNewUser, isCreatingUser] = useRequest(createUser(organisationId));
  const [deleteUser, isDeletingUser] = useRequest(deleteUserById(organisationId, userId));
  const [rolesData, getAllRoles, isFetchingRoles, errorFetchingRoles] = useFetch(getRoles(organisationId));
  const { navigate } = useOrganisationNavigate();

  const getViewUsersLink = () => {
    if (organisationId === process.env.REACT_APP_ADMIN_ORGANISATION_ID) return paths.ViewAdminUsers.link;
    return paths.ViewUsers.link;
  };

  const handleSubmit = async (email, displayName, selectedRoles) => {
    if (userId) {
      handleUpdateUser(displayName, selectedRoles);
    } else {
      handleCreateUser(email, displayName, selectedRoles);
    }
  };

  const handleUpdateUser = async (displayName, selectedRoles) => {
    try {
      let body = {
        displayName,
        NewRolesId: selectedRoles.filter((x) => !userData.assignedRoles.map((x) => x.roleId).includes(x)),
        RemovedRolesAssignmentId: userData.assignedRoles
          .filter((x) => !selectedRoles.includes(x.roleId))
          .map((x) => x.roleAssignmentId),
      };
      await saveUser(body);
      displayToast("User details updated.");
      navigate(getViewUsersLink());
    } catch {
      displayToast("An error occurred saving data.", "error");
    }
  };

  // temporarily hard coded admin role guid
  const handleCreateUser = async (email, displayName, roles) => {
    try {
      await createNewUser({ email, displayName, roles });
      displayToast("User created.");
      navigate(getViewUsersLink());
    } catch {
      displayToast("An error occurred creating the user.", "error");
    }
  };

  const handleDelete = async () => {
    displayAlert(`Delete ${userData.displayName}?`, `Please confirm you would like to delete ${userData.displayName}`, {
      ...alertTypes.confirm,
      onDone: async (result) => {
        if (!result.isConfirmed) return;
        try {
          await deleteUser();
          displayToast("User deleted.");
          navigate(getViewUsersLink());
        } catch {
          displayToast("An error occurred deleting the user.", "error");
        }
      },
    });
  };

  const handleCancel = () => navigate(getViewUsersLink());

  useEffect(() => {
    getAllRoles();
    if (userId) getUser();
  }, []);

  useEffect(
    () => (errorFetchingRoles || errorFetchingUser) && displayToast("An error occurred fetching user data.", "error"),
    [errorFetchingRoles, errorFetchingUser]
  );

  const isFetching = () => isFetchingUser || isFetchingRoles || !rolesData;

  const anyRequestInProgress = () => isFetching() || isSavingUser || isCreatingUser || isDeletingUser;

  return (
    <div className="ampScreenContainer">
      <Helmet>
        <title>{"User Management | AMP"}</title>
      </Helmet>
      <NavigationPath />
      {!isFetchingUser && !errorFetchingUser && (
        <h4 className="ampPageHeading">{userData ? userData?.displayName : "Add User"}</h4>
      )}
      <hr />
      {anyRequestInProgress() && <Loader centerInline />}
      {!isFetching() && (!userId || userData) && (
        <EditUserForm
          userData={userData}
          saveUser={handleSubmit}
          deleteUser={handleDelete}
          roles={rolesData}
          cancel={handleCancel}
          processingRequest={anyRequestInProgress()}
        />
      )}
    </div>
  );
};

export default EditUser;
