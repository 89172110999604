import Loader from "components/utility/loader/Loader";
import paths from "lib/constants/routing/paths";
import useOrganisationNavigate from "lib/hooks/navigate/useOrganisationNavigate";
import React from "react";
import { Link, useParams } from "react-router-dom";

const UsersList = ({ data }) => {
  const { generateFullPath } = useOrganisationNavigate();
  const { organisationId } = useParams();

  const getViewUserLink = () => {
    if (organisationId === process.env.REACT_APP_ADMIN_ORGANISATION_ID) return paths.ViewAdminUser.link;
    return paths.ViewUser.link;
  };

  return (
    <>
      <div className="ampCommonContainer">
        <Loader visible={!data} size={"sm"} />
        <table className="ampTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Roles</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((user) => (
              <tr key={user.userId}>
                <td>
                  <Link to={generateFullPath(getViewUserLink().replace(":userId", encodeURI(user.userId)))}>
                    {user.displayName}
                  </Link>
                </td>
                <td>{user.email}</td>
                <td>{user && user.assignedRoles && user.assignedRoles.map((r) => r.roleName).join(", ")}</td>
              </tr>
            ))}
            {(!data || !data.length) && (
              <tr>
                <td className="text-start" colSpan={3}>
                  <span>No users found.</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default UsersList;
