import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const LogsFilters = ({ updateFilters, filters, resetFilters }) => {
  const [localFilters, setLocalFilters] = useState({});

  useEffect(() => {
    setLocalFilters({ ...filters });
  }, [filters]);

  const handleChange = (e) => {
    setLocalFilters({
      ...localFilters,
      [e.target.name]: e.target.value,
    });
  };

  const search = () => {
    updateFilters(localFilters);
  };

  return (
    <div id="filtersContainer">
      <div>
        <label className="text-muted">From</label>
        <Datetime
          value={localFilters.from}
          onChange={(val) => setLocalFilters({ ...localFilters, from: val })}
          updateOnView={false}
          dateFormat="DD/MM/yyyy"
          initialViewMode="days"
        />
      </div>

      <div>
        <label className="text-muted">To</label>
        <Datetime
          value={localFilters.to}
          onChange={(val) => setLocalFilters({ ...localFilters, to: val })}
          updateOnView={false}
          dateFormat="DD/MM/yyyy"
          initialViewMode="days"
        />
      </div>

      <div>
        <label className="text-muted">Correlation Id</label>
        <input
          className="ampFormControl"
          name="correlationId"
          onChange={handleChange}
          type="text"
          value={localFilters.correlationId ?? ""}></input>
      </div>

      <div>
        <label className="text-muted">Log Level</label>
        <select className="ampFormSelect" name="logLevel" onChange={handleChange} value={localFilters.logLevel ?? ""}>
          <option value="">All</option>
          <option>Information</option>
          <option value={"BusinessError"}>Business Error</option>
          <option value={"SystemError"}>System Error</option>
        </select>
      </div>

      <div>
        <label className="text-muted">Log Message</label>
        <select className="ampFormSelect" name="logMessage" onChange={handleChange} value={localFilters.logMessage ?? ""}>
          <option value="">All</option>
          <optgroup label="Transaction Stages">
            <option>Pin Session Started</option>
            <option>Configuration Requested</option>
            <option>Pin Block Generated</option>
            <option>User Cancelled Pin Entry</option>
          </optgroup>
          <optgroup label="Information">
            <option>Device Details</option>
          </optgroup>
          <optgroup label="Errors">
            <option>Pin Session Expired</option>
            <option>Device failed attestation</option>
          </optgroup>
        </select>
      </div>

      <div>
        <label className="text-muted">Platform</label>
        <select className="ampFormSelect" name="platform" onChange={handleChange} value={localFilters.platform ?? ""}>
          <option value="">All</option>
          <option value="android">Android</option>
          <option value="ios">iOS</option>
        </select>
      </div>

      <div>
        <label className="text-muted">Application Id</label>
        <input
          className="ampFormControl"
          name="applicationId"
          onChange={handleChange}
          type="text"
          value={localFilters.applicationId ?? ""}></input>
      </div>

      <div>
        <label className="text-muted">PAN Start</label>
        <input
          className="ampFormControl"
          name="panFirstDigits"
          onChange={handleChange}
          type="text"
          value={localFilters.panFirstDigits ?? ""}></input>
      </div>

      <div>
        <label className="text-muted">PAN End</label>
        <input
          className="ampFormControl"
          name="panLastDigits"
          onChange={handleChange}
          type="text"
          value={localFilters.panLastDigits ?? ""}></input>
      </div>

      <div id="searchButtonDiv">
        <Button size="sm" variant="outline-danger" onClick={search}>
          Search Logs
        </Button>
        <Button size="sm" variant="outline-secondary" onClick={resetFilters}>
          Reset Filters
        </Button>
      </div>
    </div>
  );
};

export default LogsFilters;
