import "./Sidebar.css";
import { useEffect, useState } from "react";
import useOrganisationInfo from "lib/fetch/helpers/useOrganisationInfo";
import Loader from "components/utility/loader/Loader";

const SidebarOrganisationInfo = ({ organisationId }) => {
  const [organisationInfo, refreshOrganisationInfo] = useOrganisationInfo(organisationId);

  useEffect(() => (organisationId && !isAdminOrganisation() ? refreshOrganisationInfo() : null), [organisationId]);
  const isAdminOrganisation = () => organisationId === process.env.REACT_APP_ADMIN_ORGANISATION_ID;

  const getAdminOrganisationText = () => <>Administration</>;

  const getPartnerOrganisationText = () =>
    organisationInfo ? <>{organisationInfo.name}</> : <Loader centerInline size="sm" color="white" />;

  return (
    <div>
      <div id="sideNavOrganisationTitle">{isAdminOrganisation() ? getAdminOrganisationText() : getPartnerOrganisationText()}</div>
    </div>
  );
};
export default SidebarOrganisationInfo;
