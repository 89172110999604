import paths from "../routing/paths";
import actions from "./actions";
import permissions from "./permissions";
import resourceTypes from "./resourceTypes";

const resourcePermissions = {
  [resourceTypes.Paths]: {
    // Default
    [paths.Default.link]: [permissions.None],

    // Users
    [paths.ViewUsers.link]: [permissions.OrganisationAdmin],
    [paths.ViewUser.link]: [permissions.OrganisationAdmin],
    [paths.CreateUser.link]: [permissions.OrganisationAdmin],

    [paths.ViewAdminUsers.link]: [permissions.GlobalAdmin],
    [paths.ViewAdminUser.link]: [permissions.GlobalAdmin],
    [paths.CreateAdminUser.link]: [permissions.GlobalAdmin],

    // Organisations
    [paths.ViewOrganisations.link]: [permissions.AdminOrganisationOnly, permissions.ManageOrganisations],
    [paths.ViewOrganisation.link]: [permissions.AdminOrganisationOnly, permissions.ManageOrganisations],
    [paths.CreateOrganisation.link]: [permissions.ManageOrganisations],

    // Logs
    [paths.ViewLogs.link]: [permissions.OrganisationAdmin, permissions.ViewLogs],
  },

  [resourceTypes.Actions]: {
    [actions.EditUser]: [permissions.OrganisationAdmin],
    [actions.EditOrganisation]: [permissions.ManageOrganisations],
  },
};
export default resourcePermissions;
