const paths = {
  // Default
  Default: { name: "Default", link: ":organisationId/" },

  // Users
  ViewUsers: { name: "View Users", link: ":organisationId/users" },
  ViewUser: { name: "View User", link: ":organisationId/users/:userId" },
  CreateUser: { name: "Create User", link: ":organisationId/users/create" },

  // Admin Users
  ViewAdminUsers: { name: "View Admins", link: ":organisationId/admins" },
  ViewAdminUser: { name: "View Admin", link: ":organisationId/admins/:userId" },
  CreateAdminUser: { name: "Create Admin User", link: ":organisationId/admins/create" },

  // Organisations
  ViewOrganisations: { name: "View Organisations", link: ":organisationId/view-organisations" },
  ViewOrganisation: {
    name: "Edit Organisations",
    link: ":organisationId/organisations/:editOrganisationId",
  },
  CreateOrganisation: { name: "Create Organisations", link: ":organisationId/organisations/create" },

  // Logs
  ViewLogs: { name: "Logs", link: ":organisationId/logs" },
};

export const pathsProperties = {
  // Default
  [paths.Default.name]: { hierarchy: [] },

  // Users
  [paths.ViewUsers.name]: { hierarchy: [] },
  [paths.ViewUser.name]: { hierarchy: [paths.ViewUsers] },
  [paths.CreateUser.name]: { hierarchy: [paths.ViewUsers] },

  // Organisations
  [paths.ViewOrganisations.name]: { hierarchy: [] },
  [paths.ViewOrganisation.name]: { hierarchy: [paths.ViewOrganisations] },
  [paths.CreateOrganisation.name]: { hierarchy: [paths.ViewOrganisations] },

  // Logs
  [paths.ViewLogs.name]: { hierarchy: [] },
};

export default paths;
