import "css/table.css";
import paths from "lib/constants/routing/paths";
import useOrganisationNavigate from "lib/hooks/navigate/useOrganisationNavigate";
import React from "react";
import { Link } from "react-router-dom";
const OrganisationsList = ({ data }) => {
  const { generateFullPath } = useOrganisationNavigate();

  return (
    <div className="ampCommonContainer">
      <table className="ampTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Switch</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((org) => (
            <tr key={org.id}>
              <td>
                {
                  <Link to={generateFullPath(paths.ViewOrganisation.link.replace(":editOrganisationId", encodeURI(org.id)))}>
                    {org.name}
                  </Link>
                }
              </td>
              <td>
                {
                  <Link className="btn btn-primary btn-sm" to={generateFullPath(paths.Default.link, org.id)}>
                    Switch to {org.name}
                  </Link>
                }
              </td>
            </tr>
          ))}
          {(!data || !data.length) && (
            <tr>
              <td className="text-start" colSpan={3}>
                <span>No organisations found.</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default OrganisationsList;
