import moment from "moment";

const LogItem = ({ log }) => {
  const translatePlatform = (platform) => {
    if (platform === "android") return "Android";
    if (platform === "ios") return "iOS";
    return null;
  };

  const renderIfPresent = (label, value) => {
    return (
      value && (
        <>
          <span>{label}</span>
          <p>{value}</p>
        </>
      )
    );
  };

  return (
    <div className="logitemContainer">
      {renderIfPresent("Timestamp", moment(log.GeneratedAt)?.toLocaleString())}

      {renderIfPresent("Application Id", log.ApplicationId)}

      {renderIfPresent("Correlation Id", log.CorrelationId)}

      {renderIfPresent("Log Level", log.Level)}

      {renderIfPresent("Log Message", log.Message)}

      {renderIfPresent("Exception", log.Data?.ExceptionMessage)}

      {renderIfPresent("Validation Errors", log.Data?.ValidationErrors)}

      {renderIfPresent("Device Details", log.Data?.DeviceDetails)}

      {renderIfPresent("Cancellation Reason", log.Data?.CancellationReason)}

      {log.PanFirstDigits && renderIfPresent("PAN", `${log.PanFirstDigits} **** ${log.PanLastDigits}`)}

      {renderIfPresent("Platform", translatePlatform(log.Platform))}

      <hr />
    </div>
  );
};

export default LogItem;
