import ValidatedInput from "components/form/ValidatedInput";
import actions from "lib/constants/permissions/actions";
import resourceTypes from "lib/constants/permissions/resourceTypes";
import isAuthorizedForResource from "lib/helpers/permissions/permissionsHelper";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUserData } from "redux/user/userSlice";

const EditUserForm = ({ userData, saveUser, deleteUser, roles, cancel, processingRequest }) => {
  const [email, setEmail] = useState(userData?.email ?? "");
  const [displayName, setDisplayName] = useState(userData?.displayName ?? "");
  const [selectedRoles, setSelectedRoles] = useState(userData?.assignedRoles?.map((r) => r.roleId) ?? []);
  const [hasManagePermission, setHasManagePermission] = useState(false);
  const { data } = useSelector((state) => selectUserData(state));

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => setHasManagePermission(isAuthorizedForResource(resourceTypes.Actions, actions.EditUser, data)), [data]);

  const handleRolesClick = (e) => {
    selectedRoles.includes(e.target.id)
      ? setSelectedRoles(selectedRoles.filter((x) => x !== e.target.id))
      : setSelectedRoles([...selectedRoles, e.target.id]);
  };

  const isRoleChecked = (r) => selectedRoles?.includes(r.id);

  const save = () => {
    let errors = {};
    if (!email || !email.trim()) errors.email = "Email is required";
    if (!displayName || !displayName.trim()) errors.displayName = "Display Name is required";
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      saveUser(email, displayName, selectedRoles);
    }
  };

  if (processingRequest) return <></>;
  return (
    <div className="ampCommonContainer">
      <fieldset disabled={!hasManagePermission}>
        <div id="editUserForm">
          <label className="ampFormLabel" as="legend">
            Email
          </label>

          <ValidatedInput
            className="ampFormControl"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            value={email}
            readOnly={userData}
            error={formErrors.email}
          />

          <label className="ampFormLabel" as="legend">
            Display Name
          </label>
          <ValidatedInput
            className="ampFormControl"
            name="displayName"
            onChange={(e) => setDisplayName(e.target.value)}
            type="text"
            value={displayName}
            error={formErrors.displayName}
          />

          <label className="ampFormLabel" as="legend">
            Roles
          </label>
          <GroupedRoles roles={roles} isRoleChecked={isRoleChecked} handleRoleClick={handleRolesClick} />

          {hasManagePermission && (
            <div className="buttonDiv">
              <Button size="sm" variant="success" onClick={save}>
                {userData ? "Save Changes" : "Add User"}
              </Button>
              <Button size="sm" variant="primary" onClick={() => cancel()}>
                {"Cancel"}
              </Button>
              {userData && (
                <>
                  <hr></hr>
                  <Button size="sm" variant="danger" onClick={deleteUser}>
                    Delete User
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </fieldset>

      {!hasManagePermission && (
        <div className="ampFlexCenter">
          <Button size="sm" variant="primary" onClick={() => cancel()}>
            {"Return to Users List"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditUserForm;

const GroupedRoles = ({ roles, isRoleChecked, handleRoleClick }) => {
  const [roleGroups, setRoleGroups] = useState();

  // identify groups
  useEffect(() => {
    if (!roles) return;
    var descriptions = roles.map((r) => r.description);

    var groups = [];
    descriptions.forEach((d) => {
      try {
        var group = d.match(/\[([^)]+)\]/)[1];
        if (!groups.includes(group)) groups.push(group);
      } catch {
        console.error(`${d} does not contain the required prefix of '[GROUP NAME]`);
      }
    });

    groups.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    });

    groups = [
      groups.find((g) => g === "Global Admin"),
      groups.find((g) => g === "Organisation Admin"),
      ...groups.filter((g) => g !== "Global Admin" && g !== "Organisation Admin"),
    ].filter((x) => x);

    var tempRoleGroups = {};
    groups.forEach((g) => {
      tempRoleGroups[g] = roles.filter((r) => r.description.startsWith(`[${g}]`));
    });

    setRoleGroups(tempRoleGroups);
    console.log(tempRoleGroups);
  }, [roles]);

  if (!roleGroups) return <></>;

  console.log(roleGroups);

  const getRoleLabel = (group, role) => {
    var desc = role.description.replace(`[${group}]`, "");
    return (
      <>
        <span className="roleLabel">
          {role.name} - {desc}
        </span>
      </>
    );
  };

  return (
    <div id="rolesDiv">
      {Object.keys(roleGroups).map((group, i) => {
        return (
          <div>
            <span className="ampFormOptionGroupHeading">{group}</span>
            <hr className="m-2" />
            <div className="m-1">
              {roleGroups[group].map((r) => {
                return (
                  <Form.Check
                    type="checkbox"
                    label={getRoleLabel(group, r)}
                    name="roles"
                    id={r.id}
                    className="m-2"
                    checked={isRoleChecked(r)}
                    onChange={(e) => handleRoleClick(e)}
                    key={r.id}></Form.Check>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
