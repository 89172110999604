import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from "react";
import { fetchingUser, selectUserData, userFetched, userFetchErrored } from "redux/user/userSlice";
import { getCurrentUserDetails } from "../requests/userRequests";
import useCachedFetch from "../useCachedFetch";

export default function useCurrentUserInfo(refreshOnLoad = true) {
  const [userInfo, refresh, isFetching, error] = useCachedFetch({
    request: getCurrentUserDetails(),
    stateSelector: selectUserData,
    fetchAction: fetchingUser,
    updateAction: userFetched,
    errorAction: userFetchErrored,
    cacheDurationInMinutes: 1,
  });
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => (refreshOnLoad && isAuthenticated ? refresh() : null), [refreshOnLoad]); // check if user info needs updating whenever the component is mounted

  return [userInfo, refresh, isFetching, error];
}
