import paths, { pathsProperties } from "lib/constants/routing/paths";
import resolveRouteToTemplate from "lib/helpers/routes/resolveRouteToTemplate";
import useOrganisationNavigate from "lib/hooks/navigate/useOrganisationNavigate";
import { ReactComponent as HomeIcon } from "lib/icons/home.svg";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavigationPath.css";

const NavigationPath = () => {
  const [pathHierarchy, setpathHierarchy] = useState();
  let location = useLocation();
  const { generateFullPath, navigate } = useOrganisationNavigate();
  useState(() => {
    let template = resolveRouteToTemplate(location.pathname);
    setpathHierarchy(pathsProperties[template?.path?.name]?.hierarchy);
  });

  const getRoutes = () => {
    return pathHierarchy.map((path, i) => (
      <span key={i}>
        <Link to={generateFullPath(path.link)}>{path.name}</Link> /
      </span>
    ));
  };

  return (
    <div className="navigationPath">
      <HomeIcon className="ampClickable" onClick={() => navigate(paths.Default.link)} style={{ height: "1rem" }} /> /{" "}
      {pathHierarchy && getRoutes()}
    </div>
  );
};
export default NavigationPath;
