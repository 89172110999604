import resourcePermissions from "lib/constants/permissions/resourcePermissions";
import paths from "lib/constants/routing/paths";
import { ReactComponent as UserIcon } from "lib/icons/user.svg";
import { ReactComponent as OrganisationIcon } from "lib/icons/users.svg";

const AdminSidebarOptions = [
  {
    icon: <UserIcon />,
    text: "Admin Users",
    permissionRequired: resourcePermissions[paths.ViewAdminUsers.link],
    path: paths.ViewAdminUsers.link,
  },
  {
    icon: <OrganisationIcon />,
    text: "Organisations",
    permissionRequired: resourcePermissions[paths.ViewOrganisations.link],
    path: paths.ViewOrganisations.link,
  },
];
export default AdminSidebarOptions;
