import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import NavigationPath from "components/utility/navigation/NavigationPath";
import resourceTypes from "lib/constants/permissions/resourceTypes";
import paths from "lib/constants/routing/paths";
import { getUsersForOrganisation } from "lib/fetch/requests/userRequests";
import useFetch from "lib/fetch/useFetch";
import isAuthorizedForResource from "lib/helpers/permissions/permissionsHelper";
import useOrganisationNavigate from "lib/hooks/navigate/useOrganisationNavigate";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectUserData } from "redux/user/userSlice";
import UsersList from "./components/UsersList";

const UserManagement = () => {
  const { organisationId } = useParams();
  const [data, getUsers, isFetching, error] = useFetch(getUsersForOrganisation(organisationId));
  const { navigate } = useOrganisationNavigate();
  const { data: userData } = useSelector((state) => selectUserData(state));

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (error) displayToast("An error occurred fetching users.", "error");
  }, [error]);

  const displayUsers = () => {
    var filtered = data;
    if (data && process.env.REACT_APP_EXCLUDE_USER) {
      filtered = data.filter((d) => d.displayName !== process.env.REACT_APP_EXCLUDE_USER);
    }
    return !isFetching && <UsersList data={filtered} />;
  };

  const getCreateUsersLink = () => {
    if (organisationId === process.env.REACT_APP_ADMIN_ORGANISATION_ID) return paths.CreateAdminUser.link;
    return paths.CreateUser.link;
  };

  const displayErrorMessage = () => {
    return (
      !isFetching && (
        <>
          <div className="ampFlexCenter">
            <Button variant="outline-primary" size="lg" onClick={() => getUsers()}>
              Refresh
            </Button>
          </div>
        </>
      )
    );
  };

  const displayCreateUserButton = () => {
    return (
      !error &&
      !isFetching &&
      isAuthorizedForResource(resourceTypes.Paths, paths.CreateUser.link, userData) && (
        <div className="ampFlexCenter">
          <Button variant="outline-danger" size="md" onClick={() => navigate(getCreateUsersLink())}>
            Add User
          </Button>
        </div>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{"User Management | AMP"}</title>
      </Helmet>
      <div className="ampScreenContainer">
        <NavigationPath />
        <h1 className="ampPageHeading">Users</h1>
        <hr />
        {isFetching && <Loader centerInline />}
        {error ? displayErrorMessage() : displayUsers()}
        {displayCreateUserButton()}
      </div>
    </>
  );
};

export default UserManagement;
