import ValidatedInput from "components/form/ValidatedInput";
import actions from "lib/constants/permissions/actions";
import resourceTypes from "lib/constants/permissions/resourceTypes";
import isAuthorizedForResource from "lib/helpers/permissions/permissionsHelper";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectUserData } from "redux/user/userSlice";
const EditOrganisationForm = ({ organisationData, saveOrganisation, deleteOrganisation, cancel, processingRequest }) => {
  const [name, setName] = useState(organisationData?.name);
  const [hasManagePermission, setHasManagePermission] = useState(false);
  const { data: userData } = useSelector((state) => selectUserData(state));

  const [formErrors, setFormErrors] = useState({});

  useEffect(
    () => setHasManagePermission(isAuthorizedForResource(resourceTypes.Actions, actions.EditOrganisation, userData)),
    [userData]
  );

  const save = () => {
    let errors = {};
    if (!name || !name.trim()) errors.name = "Name is required";
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      saveOrganisation(name);
    }
  };

  if (processingRequest) return <></>;
  return (
    <div className="ampCommonContainer">
      <fieldset disabled={!hasManagePermission}>
        <div id="editOrganisationForm">
          <label className="ampFormLabel" as="legend">
            Name
          </label>
          <ValidatedInput
            className="ampFormControl"
            name="name"
            onChange={(e) => setName(e.target.value)}
            type="text"
            value={name}
            label="Name"
            error={formErrors.name}
          />

          <label className="ampFormLabel" as="legend">
            Placeholder field
          </label>
          <ValidatedInput className="ampFormControl" readOnly />

          {hasManagePermission && (
            <div className="buttonDiv">
              <Button size="sm" variant="success" onClick={save}>
                {organisationData ? "Save Changes" : "Add Organisation"}
              </Button>
              <Button size="sm" variant="primary" onClick={() => cancel()}>
                {"Cancel"}
              </Button>
              {organisationData && (
                <>
                  <hr></hr>
                  <Button size="sm" variant="danger" onClick={deleteOrganisation}>
                    Delete Organisation
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </fieldset>

      {!hasManagePermission && (
        <div style={{ marginTop: "1rem" }} className="ampFlexCenter">
          <Button size="sm" variant="primary" onClick={() => cancel()}>
            {"Return to Organisations List"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditOrganisationForm;
