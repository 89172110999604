import { alertTypes, displayAlert } from "components/utility/alerts/SweetAlert";
import { displayToast } from "components/utility/alerts/Toast";
import Loader from "components/utility/loader/Loader";
import NavigationPath from "components/utility/navigation/NavigationPath";
import paths from "lib/constants/routing/paths";
import {
  createOrganisation,
  deleteOrganisationById,
  getOrganisationById,
  updateOrganisationById,
} from "lib/fetch/requests/organisationsRequest";
import useFetch from "lib/fetch/useFetch";
import useRequest from "lib/fetch/useRequest";
import useOrganisationNavigate from "lib/hooks/navigate/useOrganisationNavigate";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import EditOrganisationForm from "./components/EditOrganisationForm";
import "./OrganisationManagement.css";

const EditOrganisation = () => {
  const { editOrganisationId } = useParams();
  const [organisationData, getOrganisation, isFetchingOrganisation, errorFetchingOrganisation] = useFetch(
    getOrganisationById(editOrganisationId)
  );
  const [saveOrganisation, isSavingOrganisation] = useRequest(updateOrganisationById(editOrganisationId));
  const [createNewOrganisation, isCreatingOrganisation] = useRequest(createOrganisation());
  const [deleteOrganisation, isDeletingOrganisation] = useRequest(deleteOrganisationById(editOrganisationId));
  const { navigate } = useOrganisationNavigate();

  const handleSubmit = async (name) => {
    if (editOrganisationId) {
      handleUpdateOrganisation(name);
    } else {
      handleCreateOrganisation(name);
    }
  };

  const handleUpdateOrganisation = async (name) => {
    try {
      let body = {
        name,
      };
      await saveOrganisation(body);
      displayToast("Organisation details updated.");
      navigate(paths.ViewOrganisations.link);
    } catch {
      displayToast("An error occurred saving data.", "error");
    }
  };

  const handleCreateOrganisation = async (name) => {
    try {
      await createNewOrganisation({ name });
      displayToast("Organisation created.");
      navigate(paths.ViewOrganisations.link);
    } catch {
      displayToast("An error occurred creating the Organisation.", "error");
    }
  };

  const handleDelete = async () => {
    displayAlert(`Delete ${organisationData.name}?`, `Please confirm you would like to delete ${organisationData.name}`, {
      ...alertTypes.confirm,
      onDone: async (result) => {
        if (!result.isConfirmed) return;
        try {
          await deleteOrganisation();
          displayToast("Organisation deleted.");
          navigate(paths.ViewOrganisations.link);
        } catch {
          displayToast("An error occurred deleting the Organisation.", "error");
        }
      },
    });
  };

  const handleCancel = () => navigate(paths.ViewOrganisations.link);

  useEffect(() => {
    if (editOrganisationId) getOrganisation();
  }, []);

  useEffect(
    () => errorFetchingOrganisation && displayToast("An error occurred fetching data.", "error"),
    [errorFetchingOrganisation]
  );

  const anyRequestInProgress = () =>
    isFetchingOrganisation || isCreatingOrganisation || isSavingOrganisation || isDeletingOrganisation;

  return (
    <div className="ampScreenContainer">
      <Helmet>
        <title>{"Organisation Management | AMP"}</title>
      </Helmet>
      <NavigationPath />
      {!isFetchingOrganisation && !errorFetchingOrganisation && (
        <h4 className="ampPageHeading">{organisationData ? "Edit" : "Add"} Organisation</h4>
      )}
      <hr />
      {anyRequestInProgress() && <Loader centerInline />}
      {!isFetchingOrganisation && (!editOrganisationId || organisationData) && (
        <EditOrganisationForm
          organisationData={organisationData}
          saveOrganisation={handleSubmit}
          deleteOrganisation={handleDelete}
          cancel={handleCancel}
          processingRequest={anyRequestInProgress()}
        />
      )}
    </div>
  );
};

export default EditOrganisation;
