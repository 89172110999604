import Swal from "sweetalert2";

export const alertTypes = {
  error: {
    icon: "error",
    showConfirmButton: true,
    confirmButtonText: null,
    allowOutsideClick: true,
    allowEscapeKey: true,
    onDone: null,
    showCancelButton: false,
    customClass: {
      confirmButton: "btn btn-lg btn-danger",
    },
  },
  confirm: {
    icon: "warning",
    showConfirmButton: true,
    confirmButtonText: "Confirm",
    allowOutsideClick: true,
    allowEscapeKey: true,
    onDone: null,
    showCancelButton: true,
    cancelButtonText: "Cancel",
    customClass: {
      confirmButton: "btn btn-md btn-success m-1",
      cancelButton: "btn btn-md btn-danger m-1",
    },
  },
};

export function displayAlert(
  title,
  message,
  {
    icon,
    confirmButtonText,
    showConfirmButton,
    allowOutsideClick,
    allowEscapeKey,
    onDone,
    customClass,
    showCancelButton,
    cancelButtonText,
  }
) {
  Swal.mixin({
    customClass,
    buttonsStyling: !customClass,
  })
    .fire({
      title,
      html: message,
      icon,
      confirmButtonText,
      showConfirmButton,
      allowOutsideClick,
      allowEscapeKey,
      showCancelButton,
      cancelButtonText,
    })
    .then((result) => (onDone ? onDone(result) : null));
}
export default Swal;
