import resourceTypes from "lib/constants/permissions/resourceTypes";
import isAuthorizedForResource from "lib/helpers/permissions/permissionsHelper";
import resolveRouteToTemplate from "lib/helpers/routes/resolveRouteToTemplate";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectUserData } from "redux/user/userSlice";

const AuthorizedRoute = ({ children }) => {
  const { data: userData } = useSelector((state) => selectUserData(state));
  const location = useLocation();

  const isAuthorized = () => {
    let resolvedTemplate = resolveRouteToTemplate(location.pathname);
    if (!resolvedTemplate) return false;

    return isAuthorizedForResource(resourceTypes.Paths, resolvedTemplate.match?.pattern?.path, userData);
  };

  return isAuthorized() ? <>{children}</> : <h1>401</h1>;
};
export default AuthorizedRoute;
