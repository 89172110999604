import resolveRouteToTemplate from "lib/helpers/routes/resolveRouteToTemplate";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "redux/user/userSlice";

const { useNavigate, useLocation, useParams } = require("react-router-dom");

const useOrganisationNavigate = () => {
  const baseNavigate = useNavigate();
  const { data: userData } = useSelector((state) => selectUserData(state));
  const location = useLocation();
  let resolvedTemplate = resolveRouteToTemplate(location.pathname);

  const navigate = (path, overrideOrganisationId = null) => {
    baseNavigate(generateFullPath(path, overrideOrganisationId));
  };

  const generateFullPath = (path, overrideOrganisationId = null) => {
    let organisationId;
    if (!overrideOrganisationId) organisationId = resolvedTemplate?.match?.params?.organisationId ?? userData?.organisationId;
    return `/${path.replace(":organisationId", overrideOrganisationId ?? organisationId)}`;
  };

  return { navigate, generateFullPath };
};

export default useOrganisationNavigate;
