import GoogleMapReact from "google-map-react";
import "../Statistic.css";
import "./Map.css";
import MapMarker from "./MapMarker";

const Map = ({ height, heading, data }) => {
  return (
    <div className="ampCommonContainer" style={{ height: height }}>
      <p className="statHeading">{heading}</p>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GMK,
        }}
        defaultCenter={{ lat: 53.01513, lng: -1.97953 }}
        defaultZoom={2}>
        {data &&
          data.map((g) => {
            return <MapMarker lat={g.latitude} lng={g.longitude} />;
          })}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
