import { Helmet } from "react-helmet-async";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <div>
      <Helmet>
        <title>{"Page not found | AMP"}</title>
      </Helmet>
      <div className="ampScreenContainer">
        <h1 className="display-6">Page not found.</h1>
      </div>
    </div>
  );
};

export default PageNotFound;
