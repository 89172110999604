import useAccessToken from "lib/aad/useAccessTokenHelper";
import { useState } from "react";
import fetchData from "./fetch";

export default function useFetch({ url, options }) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessToken } = useAccessToken();

  const execute = async (body = null, retryAttemptsLeft = 0, urlOverride = null) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      var token = await getAccessToken();
      console.log(token);
      var data = await fetchData({ token, url: urlOverride ?? url, options, body });

      setIsLoading(false);
      setData(data);
      setError(null);
    } catch (e) {
      console.log(e);
      if (retryAttemptsLeft > 0 && ![403, 401].includes(e?.response?.status)) {
        execute(body, --retryAttemptsLeft, urlOverride);
      } else {
        setError(e);
        setIsLoading(false);
      }
    }
  };

  return [data, execute, isLoading, error];
}
