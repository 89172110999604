import Loader from "components/utility/loader/Loader";
import moment from "moment";
import { useEffect, useState } from "react";
import LogItem from "./LogItem";

const LogsDisplay = ({ data, loading, totalLogs, setCorrelationId, expandedFirstRow = false }) => {
  const [expandedIds, setExpandedIds] = useState([]);

  useEffect(() => setExpandedIds([]), [data, loading]);

  useEffect(() => {
    if (expandedFirstRow && data && data.length) {
      setExpandedIds([...expandedIds, data[0].id]);
    }
  }, [data]);

  return (
    <div className="ampCommonContainer">
      {!loading && totalLogs > 0 && <p id="logsCountLabel">{totalLogs} logs found.</p>}
      <table id="logsTable" className="ampTable nowrap ampFontSizeSmall">
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Correlation Id</th>
            <th>Log Level</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            data?.map((log) => (
              <>
                <tr
                  className={`ampClickable ${expandedIds.includes(log.id) ? "expandedRowParent" : ""}`}
                  key={log.id}
                  onClick={() =>
                    setExpandedIds(
                      expandedIds.includes(log.id) ? [...expandedIds.filter((x) => x !== log.id)] : [...expandedIds, log.id]
                    )
                  }>
                  <td>{moment(log.GeneratedAt).toLocaleString()}</td>
                  <td>
                    {setCorrelationId ? (
                      <span
                        className="ampLink"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCorrelationId(log.CorrelationId);
                        }}>
                        {log.CorrelationId}
                      </span>
                    ) : (
                      log.CorrelationId
                    )}
                  </td>
                  <td>{log.Level}</td>
                  <td>{log.Message}</td>
                </tr>
                {expandedIds && expandedIds.includes(log.id) && (
                  <tr className="expandedRow">
                    <td colSpan={5}>
                      <LogItem log={log} />
                    </td>
                  </tr>
                )}
              </>
            ))}

          {(!data || !data.length) && !loading && (
            <tr>
              <td className="text-start" colSpan={4}>
                <span>No logs found.</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Loader centerInline visible={loading} size={"md"} />
    </div>
  );
};

export default LogsDisplay;
