import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import useCurrentUserInfo from "lib/fetch/helpers/useCurrentUserInfo";
import { ReactComponent as LogOutIcon } from "lib/icons/log-out.svg";
import { ReactComponent as UserIcon } from "lib/icons/user.svg";
import React from "react";
import { Container, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { loggedOut } from "redux/user/userSlice";
import "./Topbar.css";

const Topbar = () => {
  const location = useLocation();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userData] = useCurrentUserInfo();
  const dispatch = useDispatch();

  return (
    <Navbar id="topbar">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          <NavDropdown
            className={userData && isAuthenticated ? "d-flex" : "d-none"}
            title={
              <>
                <UserIcon stroke="black" />
                <span className="text-black ps-1">{userData?.displayName}</span>
              </>
            }>
            <NavDropdown.Item
              onClick={() => {
                dispatch(loggedOut());
                instance.logoutRedirect();
              }}>
              <LogOutIcon className="pe-1" />
              Log Out
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default Topbar;
