import { useEffect } from "react";
import {
  fetchingOrganisation,
  organisationfetched,
  organisationFetchErrored,
  selectOrganisationData,
} from "redux/organisation/organisationSlice";
import { getOrganisationById } from "../requests/organisationsRequest";
import useCachedFetch from "../useCachedFetch";

export default function useOrganisationInfo(organisationId) {
  const [organisationInfo, refresh, isFetching, error] = useCachedFetch({
    request: getOrganisationById(organisationId),
    stateSelector: selectOrganisationData,
    fetchAction: fetchingOrganisation,
    updateAction: organisationfetched,
    errorAction: organisationFetchErrored,
    cacheDurationInMinutes: 1,
  });

  return [organisationInfo, refresh, isFetching, error];
}
