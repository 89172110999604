import React, { useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({ itemsCount, itemsPerPage, currentPage, setCurrentPage }) => {
  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  useEffect(() => pagesCount && currentPage && setLastPageAsCurrent(), [pagesCount]);

  if (!itemsCount || !itemsPerPage) return <></>;

  const changePage = (number) => {
    if (currentPage === number) return;
    setCurrentPage(number);
  };

  const onPageNumberClick = (pageNumber) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => changePage(currentPage - 1);
  const onNextPageClick = () => changePage(currentPage + 1);

  const setLastPageAsCurrent = () => {
    if (currentPage && pagesCount && currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  };

  let isPageNumberOutOfRange;
  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isInRange = Math.abs(pageNumber - currentPage) <= 5;

    if (isPageNumberFirst || isPageNumberLast || isInRange) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item key={pageNumber} onClick={() => onPageNumberClick(pageNumber)} active={pageNumber === currentPage}>
          {pageNumber}
        </Pagination.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    }

    return null;
  });

  return (
    <Pagination>
      <Pagination.Prev onClick={onPreviousPageClick} disabled={isCurrentPageFirst} />
      {pageNumbers}
      <Pagination.Next onClick={onNextPageClick} disabled={isCurrentPageLast} />
    </Pagination>
  );
};

export default PaginationComponent;
