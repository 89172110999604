import permissions from "lib/constants/permissions/permissions";
import resourcePermissions from "lib/constants/permissions/resourcePermissions";

export default function isAuthorizedForResource(resourceType, resourceKey, userData) {
  let userPermissions = getUserPermissionsFromUserData(userData);

  //retrieve mapping for resource
  var permissionsRequired = resourcePermissions[resourceType]?.[resourceKey];
  if (!permissionsRequired) {
    console.error`No mapping was found for resource type '${resourceType}' with key '${resourceKey}'`;
    return true;
  }

  // allow all if global admin
  if (userPermissions.includes(permissions.GlobalAdmin)) return true;

  // check if resource is only for global admins
  if (permissionsRequired.length === 1 && permissionsRequired[0] === permissions.GlobalAdmin) return false;

  // check if resource is allowed if in admin organisation
  if (permissionsRequired.includes(permissions.AdminOrganisationOnly))
    return userData?.organisationId === process.env.REACT_APP_ADMIN_ORGANISATION_ID;

  // verify user has the required permission
  return (
    permissionsRequired.includes(permissions.None) || permissionsRequired.filter((p) => userPermissions?.includes(p)).length > 0
  );
}

export const getUserPermissionsFromUserData = (userData) => {
  return userData?.assignedRoles?.map((r) => r.roleName) ?? [];
};
