import paths from "lib/constants/routing/paths";
import { matchPath } from "react-router-dom";

export default function resolveRouteToTemplate(route) {
  for (var path in paths) {
    var match = matchPath({ path: paths[path].link, exact: true, strict: true }, route);
    if (match) {
      return { match, path: paths[path] };
    }
  }
  return null;
}
